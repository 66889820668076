<template>
  <!--  卡拉玛版导入学生页面 -->

  <div class="page">
    <div class="header">
      <!--  导航栏-->
      <div class="importList">
        <div class="schoolList">
          <!-- v-show="report_null === true ? 'style=color:#000':'style=color:#333'" -->
          <!-- v-show="report_null = true ? style='color:#34BBEC':'color: #333'" -->
          <!-- :type="report_null === true ? 'primary' : 'style=color:#333'" -->
          <!-- :type="report_null === false ? 'primary' : 'style=color:#333'" -->
          <el-link
            style="color: #333"
            :underline="false"
            @click="goTo('/management/importScreeningData')"
          >
            通用版
          </el-link>
        </div>
        <div class="studentList">
          <el-link
            :type="(report_edition = true ? 'primary' : 'style=color:#333')"
            :underline="false"
            @click="goTo('/detection/student1')"
          >
            克拉玛依版
          </el-link>
        </div>
      </div>
      <span>
        <el-select
          v-model="ScreeningId"
          filterable
          placeholder="请选择筛查计划"
        >
          <el-option
            v-for="item in getNotOutDateScreeningList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </span>
      <div class="header-button">
        <a
          style="text-decoration: underline"
          href="https://ks3-cn-beijing.ksyuncs.com/magic3d/test/document/karamayExport.xlsx"
          >下载Excel模板</a
        >
        <el-button @click="goTo('/detection/screeningListQuery')">
          返回
        </el-button>
      </div>
    </div>
    <div class="tips">
      <h2 style="font-weight: bold">
        导入数据用于已有筛查数据的学生录入，数据导入后筛查列表查询显示该学生为已筛查。
      </h2>
      <span>
        注： 1.先选择筛查计划，系统将会把数据导入到对应的筛查计划内。<br />
        <span class="nbspss">
          <!-- &nbsp;&nbsp; -->
          2.重复录入同一筛查计划下的同一学生数据，系统将会作覆盖处理。
          <!-- &nbsp;&nbsp; -->
       </span> <br />
       <span class="nbspss">
          3.若未按照格式导入学生数据，系统将会提示对应错误行。如遇该种情况，请检查并修改数据格式，并重新导入。
          <!-- &nbsp;&nbsp; -->
       </span>
      </span>
    </div>

    <div class="steps">
      <header>
        <h1 style="font-weight: bold">步骤一：按图中所示编辑好excel文件待传</h1>
        <div class="pic">
          <el-image
            style="width: 100%; border: 1px dashed #cccccc"
            :src="require('@/assets/detection/image-1@2x.png')"
            :preview-src-list="[require('@/assets/detection/image-1@2x.png')]"
            z-index.number="0"
          >
          </el-image>
          <el-image
            style="width: 100%; border: 1px dashed #cccccc; margin-top: 10px"
            :src="require('@/assets/detection/image-2@2x.png')"
            :preview-src-list="[require('@/assets/detection/image-2@2x.png')]"
            z-index.number="0"
          >
          </el-image>
        </div>
      </header>
      <!-- <div class="stepsText">
        <span style="font-size: 20px;">打<span style="color: red">*</span>的项目为必填项</span>
      </div>

      <div class="prompt">
        <h2 style="font-size: 16px;font-weight: 700;">导入格式 例：</h2>
        <div class="pic">
          <el-image
            style="width: 100%;border: 1px dashed #CCCCCC;"
            :src="require('@/assets/detection/sample2.png')"
            :preview-src-list="[require('@/assets/detection/sample2.png')]"
            z-index.number="1"
          >
          </el-image>
        </div>
      </div> -->
    </div>
    <div class="stepsTwo">
      <header>
        <h1 style="font-weight: bold">步骤二：上传excel文件预览要导入的名单</h1>
        <el-upload
          ref="upload"
          name="file"
          class="upload-demo"
          drag
          action="/"
          :limit="limit"
          :on-exceed="handleExceed"
          :on-remove="removeFile"
          :on-change="fileChange"
          :file-list="fileList"
          :auto-upload="false"
        >
          <i class="uploadIcon"></i>
          <div class="el-upload__text">
            点击或拖到该区域上传
            <br />
            <em style="color: red">请确保是按步骤一模板填写并填写正确</em>
          </div>
          <div class="el-upload__tip" slot="tip" :style="styleObject">
            <!-- <i v-show="hidenUpload"></i> -->
            <span v-show="hidenUpload"><i class="el-icon-paperclip"></i> 表格名称.xlsx</span>
          </div>
        </el-upload>
      </header>
    </div>
    <div class="footer">
      <template>
        <el-button
          type="primary"
          size="mini"
          round
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="submitUpload"
        >
          确认导入
        </el-button>
      </template>
    </div>
    <el-table
      :data="xlsxJsonData"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      v-show="xlsxJsonData.length > 0"
    >
      <el-table-column prop="年级名称" label="年级*" width="100" >
      </el-table-column>
      <el-table-column
        prop="班级名称"
        label="班级*"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="姓名"
        label="姓名*"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="性别" label="性别*" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="身份证号"
        label="身份证号*"
        width="170"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="民族"
        label="民族*"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="家庭住址"
        label="家庭住址"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="出生日期"
        label="出生日期*"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="左眼裸眼视力" label="左眼裸眼视力*" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼裸眼视力" label="右眼裸眼视力*" width="120" align="center">
      </el-table-column>
      <el-table-column prop="是否戴镜" label="是否戴镜" width="120" align="center">
      </el-table-column>
      <el-table-column prop="戴镜类型" label="戴镜类型" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼球镜" label="左眼球镜*" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼球镜" label="右眼球镜*" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼戴镜视力" label="左眼戴镜视力" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼戴镜视力" label="右眼戴镜视力" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼矫正视力" label="左眼矫正视力" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼矫正视力" label="右眼矫正视力" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼眼镜度数" label="左眼眼镜度数" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼眼镜度数" label="右眼眼镜度数" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼串镜检测" label="左眼串镜检测" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼串镜检测" label="右眼串镜检测" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼屈光不正" label="左眼屈光不正" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼屈光不正" label="右眼屈光不正" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼近视力(3米)" label="左眼近视力(3米)" width="140" align="center">
      </el-table-column>
      <el-table-column prop="右眼近视力(3米)" label="右眼近视力(3米)" width="140" align="center">
      </el-table-column>
      <el-table-column prop="左眼结膜炎" label="左眼结膜炎" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼结膜炎" label="右眼结膜炎" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼沙眼" label="左眼沙眼" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼沙眼" label="右眼沙眼" width="120" align="center">
      </el-table-column>
      <el-table-column
        prop="左眼柱镜"
        label="左眼柱镜*"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="右眼柱镜"
        label="右眼柱镜*"
        width="120"
        align="center"
      >
     </el-table-column>
      <el-table-column
        prop="左眼轴位"
        label="左眼轴位*"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="右眼轴位"
        label="右眼轴位*"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="左眼角膜曲率" label="左眼角膜曲率" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼角膜曲率" label="右眼角膜曲率" width="120" align="center">
      </el-table-column>
      <el-table-column prop="瞳距" label="瞳距" width="120" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import StudentsService from '@/globals/service/detection/Students.js'
import organizationService from '@/globals/service/management/organization.js'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      report_edition: false,
      ScreeningId: null,
      getNotOutDateScreeningList: [],
      fullscreenLoading: false,
      styleObject: {},
      fileName: '',
      file: '',
      limit: 1,
      hidenUpload: true,
      confirmToImport: 0,
      errmesg: [],
      fileList: [],
      xlsxJsonData: [],
      reviewForm: ''
    }
  },
  mounted () {
    //   获取筛选计划
    organizationService
      .getNotOutDateScreeningList({
        startPage: 1,
        pageSize: 10000
      })
      .then((res) => {
        // console.log('77', res)
        const data = []
        res.data.list.forEach((item) => {
          const zhi = data.some((items) => items.value === item.id)
          if (!zhi) {
            data.push({
              value: item.id,
              label: item.screeningName
            })
          }
        })
        // 获取筛选计划
        this.getNotOutDateScreeningList = data
      })
  },
  methods: {
    // goTo (path) {
    //   this.$router.push({ path: path })
    // },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
      this.report_edition = true
    },
    // 处理时间的问题
    formatDates (numb, format) {
      const old = numb - 1
      const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60)
      const time = new Date(1900, 0, old, 0, 0, t)
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const date = time.getDate()
      return year + format + (month < 10 ? '0' + month : month) + format + (date < 10 ? '0' + date : date)
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleExceed (e) {
      // 判断是否只能上传一个文件，超过提示报错
      this.$msgbox({
        title: '错误',
        message: '只能上传一个文件哦',
        type: 'error'
      })
        .catch(() => {})
        .then(() => {})
    },
    fileChange (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$msgbox({
          title: '错误',
          message: '上传文件只能为excel文件，且为xlsx,xls格式',
          type: 'error',
          position: 'cneter'
        })
          .catch(() => {})
          .then(() => {})
        this.fileList = []
        this.file = ''
        return false
      }

      const isLtSize = file.size / 1024 / 1024 < 10
      if (!isLtSize) {
        this.$msgbox({
          title: '错误',
          message: '上传文件大小不能超过 10MB!',
          type: 'error'
        })
          .catch(() => {})
          .then(() => {})
        this.fileList = []
        this.file = ''
        return false
      }
      this.file = file.raw
      this.fileName = file.name
      this.importExcel(fileList[0])
      this.hidenUpload = false
    },
    // 选择/移除文件时触发
    removeFile (file, fileList) {
      this.fileList = []
      this.fileName = ''
      this.file = ''
      this.xlsxJsonData = []
      this.hidenUpload = true
    },
    // 导入
    importExcel (file) {
      // console.log('11', file)
      const types = file.name.split('.')[1]
      const fileType = ['xlsx', 'xls'].some((item) => item === types)
      //   console.log('fileType', fileType)
      if (!fileType) {
        alert('格式错误！请下载模板编辑后上传')
        return
      }
      this.file2Xce(file).then((tabJson) => {
        // console.log('23', tabJson)
        tabJson.forEach((dateBirth) => {
          // let key
          // console.log('dateBirth', dateBirth.sheet)
          for (let index = 0; index < dateBirth.sheet.length; index++) {
            const element = dateBirth.sheet[index]
            // console.log('element', element)
            // if (element['出生日期']=s!=null && s!="") {
            if (element['出生日期'] > -1) {
              element['出生日期'] = this.formatDates(element['出生日期'], '/')
              // console.log('1', element['出生日期'])
            }
          }
        })
        if (tabJson && tabJson.length > 0) {
          this.xlsxJsonData = tabJson[0].sheet
        }
        // console.log('this.xlsxJsonData', this.xlsxJsonData)
      })
    },
    file2Xce (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          // console.log(data)
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          // console.log('wb', this.wb.Sheets.Sheet1)
          // console.log('w3b', this.wb.SheetNames[0])
          const result = []
          // const xlsxLth = this.wb.Sheets[this.wb.SheetNames[0]]['!ref']
          // let stopX = xlsxLth.substr(xlsxLth.indexOf(':')+1,this.wb.Sheets[this.wb.SheetNames[0]]["!ref"].length);
          //   const stopX = 'V22'
          //   this.wb.Sheets[this.wb.SheetNames[0]]['!ref'] = 'A2:' + stopX
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
            // console.log('66', XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]))
          })
          // console.log('result', result)
          resolve(result)
        }
        reader.readAsBinaryString(file.raw)
        // console.log('88', reader)
      })
    },
    // 导入 end
    // 点击按钮上传
    submitUpload () {
      const that = this
      if (this.file === '') {
        this.$msgbox({
          title: '错误',
          message: '上传文件不能为空',
          type: 'error',
          // showCancelButton
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: '取消'
          // cancelButtonText: '取消',
          // cancelButtonText: '放弃修改'
        })
          .catch(() => {})
          .then(() => {})
        return false
      }

      if (!this.ScreeningId) {
        this.$msgbox({
          title: '错误',
          message: '请先选择计划',
          type: 'error',
          // showCancelButton
          showCancelButton: false
          // confirmButtonText: '确定',
          // cancelButtonText: '取消'
        })
          .then(() => {})
          .catch(() => {})
        return false
      }
      // console.log('this.file', this.file)
      that.fullscreenLoading = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('uid', storage.get('uid'))
      formData.append('loginKey', storage.get('loginKey'))
      formData.append('screeningId', this.ScreeningId)

      // const config = {
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // }
      // console.log(formData)
      //  StudentsService.import(formData, config)
      StudentsService.imkaramay(formData)
        .then((res) => {
          // console.log('66', res)
          const failureList = res.data.failureList || []
          const schoolList = res.data.schoolList || []
          let title = '成功'
          let type = 'success'
          // let duration = 2000
          if (failureList.length > 0 || schoolList.length > 0) {
            title = '警告'
            type = 'warning'
            // duration = 0
          }
          that.$msgbox({
            title: title,
            // duration: duration,
            message: res.data.msg || '',
            type: type
          })
          // console.log('fileList', this.fileList)
          // console.log('fileName', this.fileName)
          // console.log('file', this.file)
          // console.log('xlsxJsonData', this.xlsxJsonData)
          that.fullscreenLoading = false
          that.fileList = []
          that.fileName = ''
          that.file = ''
          that.xlsxJsonData = []
        })
        .catch(() => {
          that.fullscreenLoading = false
        })
        .then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0 25px;
  background: #fff;
  background-color: #fff;
  min-height: 100%;
  text-align: center;
  color: #333;
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    border-bottom: 1px solid #cccccc;
    font-size: 15px;
    font-weight: 500;
    color: #333;
    .importList {
      display: flex;
      // height: 20px;
      align-items: center;
      // margin: 10px 0 16px 0;
      // padding: 0 0 10px 0;
      // border-bottom: 1px solid #CCCCCC;
    }
    .schoolList,
    .studentList {
      display: flex;
      float: left;
      padding-right: 40px;
    }
    .el-select {
      position: absolute;
      top: 27px;
      right: 224px;
    }
    .header-button {
      display: flex;
      align-items: center;
    }
    span {
      // border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
      /deep/.el-input__inner {
        background-color: #05baec;
        &::-webkit-input-placeholder {
          color: #fff;
        }
      }
    }
    a {
      padding-right: 10px;
      //   color: #34BBEC ;
    }
  }
  .upload-demo {
    padding: 8px 0;
  }
  .tips {
    width: 100%;
    background: rgba(245, 98, 0, 0.05);
    border-radius: 4px;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    padding: 8px;
    border: 1px solid #fa6401;
    margin-top: 15px;
    .nbspss{
      padding-left: 35px;
    }
  }

  .steps {
    padding-top: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
  }

  .pic {
    margin-top: 20px;
  }

  .stepsText {
    span {
      text-align: left;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .prompt {
    margin-top: 20px;
  }

  .pic {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stepsTwo {
    padding-top: 20px;
    text-align: left;
    font-weight: bold;
    font-size: 20px;

    .upload-demo {
      .uploadIcon {
        display: inline-block;
        width: 61px;
        height: 51px;
        margin-top: 40px;
        justify-content: center;
        background-size: 61px 51px;
        background-image: url('./../../assets/detection/image/update@2x.png');
      }
       ::v-deep .el-upload-list{
        width: 40%;
      }
      ::v-deep .el-icon-document:before {
        color: #00B42A;
        content: "\e77d";
      }
      ::v-deep .el-upload-list__item-name{
        color: #00B42A;
      }
      ::v-deep .el-upload-list__item .el-icon-close{
        color: #00B42A;
      }
    }

    .el-upload__tip {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-size: 10px 10px;
        background-image: url('./../../assets/detection/image/附件@2x.png');
      }
    }
  }

  .footer {
    display: flex;
    margin: 10px 0 10px 0;
  }
  .el-image-viewer__wrapper .el-image-viewer__canvas .el-image-viewer__img {
    max-width: unset !important;
    max-height: unset !important;
  }
}

.page .header-button {
  ::v-deep a {
    color: #34bbec;
  }
}
</style>
